.imgWe {
  width: 100% !important;
  height: 300px !important;

  background-color: white !important;
  padding: 0 !important;
  text-shadow: 2px 2px 2px rgb(245, 245, 245);
}

.contWe {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  height: 100%;
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}
