.contGrid {
  background-image: url(../../assets/rastreo/plannigRouter.jpg);
  background-size: cover !important;
  background-position: center !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.contWe {
  padding: 0px 0px;
  height: 700px;
  padding-top: 90px !important;
  padding-bottom: 90px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  background-image: url(../../assets/konteo/feature.jpg);
  background-size: cover !important;
  background-position: center !important;
}
.contContactRow {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;

  padding: 70px !important;
}
.conTitle {
  color: rgb(255, 255, 255);

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 35px !important;
  font-size: 40px !important;
  font-weight: 600 !important;
}
.textLetter {
  color: rgb(255, 255, 255);
  min-width: 480px;
  margin-bottom: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 22px !important;
  font-weight: 600 !important;

  /* text-shadow: 2px 2px 2px rgb(0, 0, 0); */
}

.textLetterTitle {
  color: rgb(255, 255, 255);
  max-width: 480px;
  margin-bottom: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px !important;
}
.conCenter {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
}
.iconList {
  font-size: 60px !important;
  color: rgb(255, 255, 255) !important;
}
.listArt {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 60px;
}
.confirst {
  height: 175px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.conSecond {
  height: 175px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.logo {
  width: 500px !important;
  height: 250px !important;
  background-color: white !important;
  padding: 0 !important;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .confirst {
    height: 40px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .conTitle {
    margin-bottom: 5px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .conCenter {
    display: flex;

    justify-content: center;
    width: 100%;
    margin-top: 15px;
  }
  .iconList {
    font-size: 40px !important;
    color: rgb(255, 255, 255) !important;
  }
  .listArt {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 40px;
  }
  .textLetter {
    color: rgb(255, 255, 255);
    min-width: 200px;
    margin-bottom: 15px;
    display: flex;
    justify-content: left;
    line-height: 30px;
    align-items: center;
    font-size: 22px !important;
    font-weight: 600 !important;

    /* text-shadow: 2px 2px 2px rgb(0, 0, 0); */
  }

  .textLetterTitle {
    color: rgb(255, 255, 255);
    max-width: 200px;
    margin-bottom: 15px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 18px !important;
  }
}
