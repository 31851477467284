.carouselCont {
  overflow: hidden !important;
}
.titleProduct {
  color: #31495d;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 40px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px #1a2c3b;
}

.contProduct {
  padding-bottom: 20px;
}
.segProduct {
  /* background-color: #31495d !important; */
  padding-top: 60px !important;
  padding-bottom: 100px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* margin-bottom: 20px !important; */
  /* background-color: #ffffff !important; */
}

.weLetter {
  text-align: justify !important;
}

.logo {
  width: 70% !important;
  height: 320px !important;
  /* background-color: white !important; */
  padding: 0 !important;
  margin: 0 !important;
}
@media only screen and (max-width: 768px) {
  .titleProduct {
    font-size: 30px !important;
  }
  .logo {
    width: 100% !important;
    height: 120px !important;
    /* background-color: white !important; */
    padding: 0 !important;
    margin: 0 !important;
    /* cursor: pointer !important;
  box-shadow: 3px 3px 3px rgb(36, 36, 36) !important; */
  }
}
