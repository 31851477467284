.imgWe {
  width: 100% !important;
  height: 200px !important;

  background-color: white !important;
  padding: 0 !important;
  text-shadow: 2px 2px 2px rgb(245, 245, 245);
}

.contContact {
  background-image: url(../../assets/email.jpg);
  background-size: cover !important;
  background-position: center !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.titleCheck {
  color: white;
}
.contContactRow {
  background-color: rgba(0, 0, 0, 0.6);

  padding-top: 70px !important;
  padding-bottom: 100px !important;
}

.titleContact {
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}
.titleContactForm {
  color: white;
  margin-bottom: 55px !important;
  font-size: 40px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}
.textContact {
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 22px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}
.input {
  background-color: transparent !important;
  border-top: none;
  border-left: none;
  border-right: none;
  color: white;

  width: 100% !important;
  margin-bottom: 10px;
  font-size: 18px !important;
}
.titleSend {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 22px !important;
  color: white !important;
}
.titleErrors {
  margin-top: 15px;
  font-weight: bold !important;
  margin-bottom: 5px;
  font-size: 20px !important;
  font-weight: bold !important;
  color: rgb(233, 26, 26) !important;
}
.contForm {
  width: 80% !important;
}
.columnForm {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}
.flexForm {
  display: flex;
  justify-content: space-between;
}
.checkCalc {
  display: flex;
  justify-content: space-between;
}
.checkCalcText {
  color: white !important;
  font-size: 1.4rem !important;
  font-weight: 700 !important;
}
@media only screen and (max-width: 768px) {
  .titleContact {
    font-size: 30px !important;
  }
  .textContact {
    font-size: 18px !important;
  }
  .titleContactForm {
    font-size: 30px !important;
  }
  .checkCalcText {
    font-size: 1rem !important;
  }
  .contForm {
    width: 100% !important;
  }
}
