.positiveButton {
  margin: 0 0.5rem 0 0 !important;
  padding: 0.75rem 1rem !important;
  min-width: 7rem !important;
  color: white !important;
}

.negativeButton {
  margin: 0 1rem 0 0rem !important;
  padding: 0.75rem 1rem !important;
  min-width: 7rem !important;
  color: white !important;
}

.dateContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 20px 20px !important;
}

.textSubtitle {
  font-family: verdana, sans-serif;
  color: #2d4cb3;
  font-size: 22px;
}

.textCont {
  font-size: 14px;
  font-weight: 600;
}

.header {
  display: flex !important;
  align-items: baseline !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: #3055ce !important ;

  font-size: 22px !important;
}

.content {
  padding: 0px 0px 20px 20px !important;
}

.titleText {
  font-weight: 700 !important;
  color: #3055ce !important ;
  font-size: 1.2rem !important;
}
