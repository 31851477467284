.contMenu {
  border: 0 !important;
}

.contMenu::before {
  content: none !important;
}

.constNavbar {
  background: #c2e7fa;
}

.constItem {
  padding: 0 !important;
  border: 0 !important;
}
.sidebar {
  top: 70px !important;
  padding-top: 18px !important;
  width: 168px !important;
  /* max-height: 140px !important; */
}
.contItem {
  width: 200px !important;
  padding-top: 100px !important;
}
.constMenu {
  background: rgb(104, 143, 182) !important;
  background: linear-gradient(68deg, rgba(104, 143, 182, 1) 0%, rgba(121, 158, 202, 1) 30%, rgba(39, 63, 87, 1) 92%, rgba(30, 51, 73, 1) 98%) !important;
  box-shadow: 3px 3px 3px rgb(36, 36, 36) !important;
  height: 70px !important;
  z-index: 99 !important;
}
.constMenuBar {
  background: rgba(0, 0, 0, 0.6) !important;
  /* min-height: 240px !important; */
  margin-top: 70px !important;
  height: 45px !important;
}

.barIcon {
  margin-top: 11px !important;
  margin-left: 8px !important;
  font-size: 22px !important;
  color: white !important;
}
.barIconBack {
  margin-top: 14px !important;

  font-size: 14px !important;
  color: white !important;
}
.dropdownText {
  color: rgba(30, 51, 73, 1) !important;
  /* background: rgba(30, 51, 73, 1) !important; */
}
.menuItem {
  font-size: 22px !important;
  height: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 0 !important;
  padding-top: 5px !important;
  color: white !important;
  padding-bottom: 15px !important;
}

.menuItem::before {
  content: none !important;
}

.menuItemSiderbar {
  height: 100%;
  width: 40px;
  padding: 0 !important;

  border: 0 !important;
  padding-top: 5px !important;
  color: white !important;
  padding-bottom: 15px !important;
}
.menuItemSiderbar::before {
  content: none !important;
}

.menuSubItem {
  font-size: 22px !important;
  height: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 0 !important;
  padding-top: 5px !important;
  color: white !important;
  padding-bottom: 15px !important;
}
.menuSubItem::before {
  content: none !important;
}
.menuItemImage {
  font-size: 22px !important;
  height: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 15px !important;
  display: flex !important;
  justify-content: center !important;
}

.menuItemImage::before {
  content: none !important;
}

.imageLogo {
  width: 16rem;
  height: 3.5rem;
  padding: 0;

  margin: 0;
}
@media only screen and (max-width: 768px) {
  .imageLogo {
    width: 13rem;
    height: 3rem;
    padding: 0px;

    margin: 0px !important;
  }
  .menuItemImage {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }
  .titleProduct {
    font-size: 30px !important;
  }
  .menuSubItem {
    font-size: 19px !important;
  }
}

@media only screen and (max-width: 910px) {
  .menuItem {
    font-size: 18px !important;
  }
}
