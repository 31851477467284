.titleProduct {
  color: white;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  font-size: 40px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px #1a2c3b;
}

.titleFoot {
  color: white;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  font-size: 40px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px #1a2c3b;
}
.segProduct {
  background-image: url(../../assets/konteo/calculate.png);
  background-size: cover !important;
  background-position: center !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  /* height: 850px !important; */
  /* margin-bottom: 20px !important; */
  /* background-color: #ffffff !important; */
}
.contList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
.contentInput {
  margin: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 5px !important;
}
.contCalculator {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-bottom: 15px;
}
.contCal {
  display: flex;
  justify-content: center;
}
.contText {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  color: white !important;
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  width: 100% !important;
  margin-bottom: 20px !important;
}
.contTextData {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  width: 100% !important;
  margin-bottom: 20px !important;
}
.contTitle {
  margin-right: 5px !important;
}
.contTextinput {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  color: white !important;
  flex-wrap: wrap;
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  width: 520px !important;
  margin-bottom: 20px !important;
}
.contContactRow {
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
.labelTitle {
  color: white;
  margin-top: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
  width: 295px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-right: 10px;
}

.contComment {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}
.comment {
  width: 80% !important;
}
.labelTitlePlan {
  color: white;
  margin-top: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
  width: 45px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-right: 10px;
}
.labelTitleData {
  color: white;

  display: flex;
  justify-content: left;
  align-items: center;

  width: 520px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-right: 10px;
}
.labelTitleDesc {
  color: white;
  line-height: 25px !important;
  display: flex;
  justify-content: left;
  align-items: center;

  font-size: 20px !important;
  font-weight: 600 !important;
}
.labelDataDesc {
  color: white;

  font-size: 20px !important;
  font-weight: 500 !important;
}
.labelList {
  color: white;
  width: 33%;
  font-size: 16px !important;
  font-weight: 500 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputList {
  width: 95px !important;
  background-color: transparent !important;
  border-top: none;
  border-left: none;
  border-right: none;
  color: white;
}
.labelTitleList {
  color: white;

  font-size: 20px !important;
  font-weight: 500 !important;
}
.contTextDataDesc {
  margin-bottom: 15px !important;
  display: flex !important;
  justify-content: left !important;
  align-items: center !important;
  color: white !important;
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  width: 100% !important;
}
.contValue {
  display: flex;
  align-items: center;
}

.searchInputStyleNumber {
  display: flex !important;
  align-items: center !important;
  width: 90px !important;
  margin-bottom: 5px !important;
}
.checkBoxPlan {
  display: flex !important;
  align-items: center !important;
}
.radioPlan {
}
.inputSearch {
  margin: 0 !important;
  width: 100% !important;
  border-radius: 0px !important;
  border-right: 1px solid #e0e0e0;
  background-color: transparent !important;
  border-top: none;
  border-left: none;
  border-right: none;
  color: white;
}
.labelPrice {
  white-space: nowrap !important;
  min-width: 50px;
  max-width: 50px;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  color: white !important;
  margin-left: 15px !important;
  font-size: 20px;
  font-weight: 600 !important;
}

.labelPriceOnly {
  display: flex !important;
  justify-content: left !important;
  white-space: nowrap !important;
  min-width: 70px;
  color: white !important;
  font-size: 20px !important;
  margin-left: 105px !important;
}
.contTotal {
  display: flex !important;
  align-items: center !important;

  color: white !important;
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  margin-left: 155px !important;
}
.labelTitleTotal {
  display: flex !important;
  justify-content: left !important;
  white-space: nowrap !important;

  color: white;

  font-size: 20px !important;
  font-weight: 600 !important;

  margin-right: 0.8rem;
}
.labelPriceTotal {
  display: flex !important;
  justify-content: left !important;
  white-space: nowrap !important;
  font-size: 26px !important;
  font-weight: 600 !important;
  color: white !important;
  margin-left: 15px !important;
  text-decoration: underline;
}
.checkBox {
  margin-right: 15px !important;
}
.textLetter {
  color: white;
  min-width: 480px;
  margin-bottom: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}
.contFlex {
  display: flex;
  align-items: center;
}
.contPlan {
  display: flex;
  justify-content: center;
  width: 100%;
}
.contListPlan {
  width: 80% !important;
}
.radioSize {
  font-size: 40px !important;
}
.groupPlan {
  height: 17px;
}
.labelOpc{
  font-size: 1rem;
  margin-left: 5px;
}
@media only screen and (max-width: 768px) {
  .titleProduct {
    font-size: 24px !important;
  }
  .contText {
    display: block !important;
  }
  .labelPriceTotal {
    font-size: 20px !important;
  }
  .labelTitleTotal {
    display: flex !important;
    justify-content: left !important;
    white-space: nowrap !important;

    color: white;

    font-size: 20px !important;
    font-weight: 600 !important;

    margin-right: 0;
  }
  .contTotal {
    display: flex !important;
    align-items: center !important;

    color: white !important;
    font-size: 1.4rem !important;
    font-weight: 700 !important;
    margin-left: 0px !important;
  }
  .contCalculator {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 15px;
  }
  .titleFoot {
    font-size: 30px !important;
  }
  .contListPlan {
    width: 100% !important;
  }
  .labelDataDesc {
    color: white;

    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .contComment {
    margin-top: 10px !important;
  }
}
