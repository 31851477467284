/* .slider {
  min-height: 2500px !important;
} */
.carouselCont {
  overflow: hidden !important;
}

.titleProduct {
  color: white;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 40px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}

.contProduct {
  padding-bottom: 20px;
}
.columnCenter {
  display: flex !important;
  justify-content: center !important;
}
.segProduct {
  background-color: #31495d !important;
  padding-top: 60px !important;
  padding-bottom: 100px !important;
  width: 100% !important;

  margin-left: 0px !important;
  margin-right: 0px !important;
  /* margin-bottom: 20px !important; */
  /* background-color: #ffffff !important; */
}

.weLetter {
  text-align: justify !important;
}
/* .contProductTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../assets/factImg.png);
  height: 320px !important;
  width: 100% !important;
} */
.logo {
  width: 350px !important;
  height: 250px !important;
  background-color: white !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
  box-shadow: 3px 3px 3px rgb(36, 36, 36) !important;
}
.logo:hover {
  transform: scale(1.05);
  box-shadow: 6px 6px 6px rgb(13, 17, 51) !important;
}
@media only screen and (max-width: 768px) {
  .titleProduct {
    font-size: 30px !important;
  }
}
