.imgWe {
  width: 100% !important;
  height: 400px !important;

  background-color: white !important;
  padding: 0 !important;
  text-shadow: 2px 2px 2px rgb(245, 245, 245);
}

.contWe {
  padding: 0px 0px;
  height: 700px;
  margin-right: 0px !important;
  margin-left: 0px !important;
  background-image: url(../../assets/konteo/feature.jpg);
  background-size: cover !important;
  background-position: center !important;
}
.contContactRow {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;

  padding: 70px !important;
}
.conTitle {
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 35px !important;
  font-size: 40px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}
.textLetter {
  color: white;
  min-width: 480px;
  margin-bottom: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 22px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}
.conCenter {
  display: flex;
  justify-content: center;
  width: 100%;
}
.conCenter {
}
@media only screen and (max-width: 768px) {
  .textLetter {
    font-size: 18px !important;
    min-width: 300px;
  }
}
