/* .slider {
  min-height: 2500px !important;
} */
.carouselCont {
  width: 100% !important;
  height: 700px !important;
  pointer-events: none !important;
}
.imgWe {
  width: 100% !important;
  height: 700px !important;
  background-image: url(../../assets/slider1.jpg);
  background-size: cover !important;
  background-position: center !important;
  padding: 0 !important;

  margin: 0 !important;
}
.column {
  /* padding: 0 !important; */
  padding: 0 !important;
  margin: 0 !important;
}
.grid {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.imgWe2 {
  width: 100% !important;
  height: 700px !important;
  background-image: url(../../assets/slider2.jpg);
  background-size: cover !important;
  background-position: center !important;
  padding: 0 !important;

  margin: 0 !important;
}
.imgWe3 {
  width: 100% !important;
  height: 700px !important;
  background-image: url(../../assets/slider3.jpg);
  background-size: cover !important;
  background-position: center !important;
  padding: 0 !important;

  margin: 0 !important;
}

.contContactRow {
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleSlider {
  color: white;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 3px solid;
  border-bottom: 3px solid;
  transform: translate(-50%, -50%);

  font-size: 35px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}

.contSlider {
  color: white;
  width: 100%;
  font-size: 25px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}
@media only screen and (max-width: 768px) {
  .imgWe {
    height: 600px !important;
  }
  .imgWe2 {
    height: 600px !important;
  }
  .imgWe3 {
    height: 600px !important;
  }
  .titleSlider {
    font-size: 25px !important;
  }
}
