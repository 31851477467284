.contWe {
  /* margin: 0px !important; */

  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 0px 0px !important;
  height: 700px !important;
  width: 100% !important;
  background-image: url(../../assets/maxmonitor/featureWhatIs.jpg);
  background-size: cover !important;
  background-position: center !important;
}
.contContactRow {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  display: flex;
  justify-content: center;

  align-items: center;
  /* padding: 70px !important; */
}
.conTitle {
  color: white;
  height: 80px;

  font-size: 30px !important;
  border-top: 3px solid;
  border-bottom: 3px solid;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
  width: 460px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.conWidth {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.conWidthTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.contText {
  width: 900px;
}
.textLetter {
  color: white;

  margin-top: 30px !important;
  max-width: 80%;
  font-size: 22px !important;

  text-shadow: 2px 2px 2px rgb(0, 0, 0);
  line-height: 30px;
}
.conLetter {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .conTitle {
    color: white;
    height: 80px;
    margin-top: 100px;
    font-size: 24px !important;
    border-top: 3px solid;
    border-bottom: 3px solid;
    font-weight: 600 !important;
    text-shadow: 2px 2px 2px rgb(0, 0, 0);
    width: 360px !important;
  }
  .textLetter {
    color: white;

    margin-top: 30px !important;
    max-width: 80%;
    font-size: 18px !important;

    text-shadow: 2px 2px 2px rgb(0, 0, 0);
    line-height: 30px;
  }
  .contWe {
    height: 800px;
  }
}
