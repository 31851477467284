.columnCenter {
  display: flex !important;
  justify-content: center !important;
}
.segProduct {
  background-color: #779cc8 !important;

  width: 100% !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  background-image: url(../../assets/rastreo/features.jpg);
  background-size: cover !important;
  background-position: center !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* margin-bottom: 20px !important; */
  /* background-color: #ffffff !important; */
}

.contContactRow {
  background-color: rgba(0, 0, 0, 0.8);

  padding-top: 70px !important;
  padding-bottom: 100px !important;
}

.cardCont {
  display: flex;
  width: initial !important;

  /* justify-content: spa; */
  flex-wrap: wrap;
}
.twoCard {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  margin: 20px;
  /* background-color: rgb(6, 6, 6); */
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);

  width: 170px;
}
.textLetter {
  color: rgb(249, 249, 249);
  /* min-width: 150px; */
  margin-bottom: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 16px !important;
  font-weight: 600 !important;
  /* text-shadow: 2px 2px 2px rgb(35, 35, 35); */
}
.cardHeader {
  min-height: 100px !important;
  max-height: 100px !important;
  min-width: 100px !important;
  margin-top: 10px !important;
  max-width: 100px !important;
  text-shadow: 5px rgb(0, 0, 0);
  object-fit: cover;
}
.cardHeaderCont {
  display: flex;
  justify-content: center;
  width: 100%;
}
.cardBody {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  padding: 20px;
  min-height: 40px;
  font-size: 13px;
  width: 100%;
  margin: 0 0 0px;
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}

.titleProduct {
  color: white;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 40px !important;
  font-weight: 600 !important;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}
@media only screen and (max-width: 768px) {
  .titleProduct {
    font-size: 30px !important;
  }
}
