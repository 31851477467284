.mensagge {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  z-index: 2;
}

.mensaggeCont {
  width: 350px !important;
}

@media only screen and (max-width: 768px) {
  .mensagge {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .messajeLetter {
    font-size: 20px !important;
  }
  .mensaggeCont {
    width: 220px !important;
  }
  .messajeIcon {
    font-size: 28px !important;
  }
}
