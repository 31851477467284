.background {
  background-color: #31495d !important;
  padding-left: 50px !important;
}
.contBackground {
  height: 250px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background-color: #31495d !important;
}
.text {
  font-size: 20px;

  width: 100%;
}
.textNumber {
  min-width: 147px !important;
  max-width: 147px !important;
  width: 147px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.textContNumber {
  display: flex !important;
  align-items: center !important;
  font-size: 20px !important;
  flex-wrap: wrap !important;
}
.textClick {
  font-size: 20px;
  cursor: pointer;
  width: 100% !important;
}
.textClick:hover {
  color: #d4d4d4 !important;
}
@media only screen and (max-width: 768px) {
  .background {
    padding-left: 0px !important;
  }
  .textSrl {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    width: 100% !important;
    display: flex;
    justify-content: left !important;
  }
}
